
.linkBtn {
  display: block;
  // text-decoration: underline;
  padding: 3px;
  text-align: left;

  &:hover {
    background-color: var(--light);
  }
}