$theme-colors: (
  'primary': #ea7b00,
);

@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-icons/font/bootstrap-icons.css';

body,
html,
#root {
  height: 100%;
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.form-style-heading {
  font-weight: bold;
  font-style: italic;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
  font-size: 15px;
  padding-bottom: 3px;
  margin-top: 40px;
}

.required:after {
  content: '*';
  color: red;
  font-weight: normal;
}

.custom-file-label {
  white-space: nowrap;
}

.has-error {
  border: 1px solid var(--danger);
}

.form-errors {
  color: var(--danger);
  font-weight: bold;
}

.form-label {
  font-weight: bold;
}

.is-invalid .invalid-feedback {
  display: block;
}

pre {
  background-color: lightgray;
  padding: 10px;
}

.input-options > .row {
  margin-bottom: 0.75rem !important;
}

.pill-button {
  text-transform: uppercase;
  font-size: 11px;
}

.fadeOut {
  opacity: 0;
  transition: opacity 1s linear;
}

.deleted {
  text-decoration: line-through;
  opacity: 0.5;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.btn-unstyled {
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

.insuredRow:nth-child(odd) {
  background-color: var(--light);
}

.insuredRow {
  padding: 0.5rem 0.5rem 1rem;
  border: 1px solid #ccc;
}

.issue-date {
  position: relative;

  small {
    padding-left: 12px;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
  }
}
.show-check {
  small {
    padding-left: 16px;
  }

  &:before {
    top: 2px;
    font-family: bootstrap-icons !important;
    content: '\f26e';
    color: var(--success);
    font-size: 1.2rem;
  }
}
.show-plus {
  &:before {
    top: 1px;
    content: '+';
  }
}

.justify-self-end {
  justify-content: flex-end;
}

.client-scenarios:nth-of-type(odd) {
  background-color: $gray-200;
}